@import '../../variables.scss';

.ErrorContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.ErrorContent__Icon {
    font-size: 32px;
}

.ErrorContent__Title {
    margin: 0;
    font-size: 16px;
}

.ErrorContent__Message {
    margin: 0;
}
