@import '../../variables.scss';

.Layout__Container {
    @media #{$small-down} {
        // margin-left: 260px;
    }
    @media #{$medium-up} {
        margin-left: 260px;
    }
}
.Layout__Main {
    @media #{$small-down} {
        padding: 16px 12px;
        padding-top: $topBarHeight + 24px;
    }
    @media #{$medium-up} {
        padding: 32px;
    }
}
