@import '../../variables.scss';

.MyLinearProgress {
    width: 100px;
    opacity: 0;
    transition: opacity 0.5s linear;

    &.fullWidth {
        width: 100%;
    }

    &.show {
        opacity: 1;
    }
}
