@import '../../variables.scss';

@keyframes drawer_reveal_horizontal {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes drawer_reveal_vertical {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.MyDrawer {
    &.MyDrawer--anchor-right {
        .MuiPaper-root {
            animation: drawer_reveal_horizontal ease 0.2s;
        }
    }
    &.MyDrawer--anchor-bottom {
        .MuiPaper-root {
            animation: drawer_reveal_vertical ease 0.2s;
        }
    }

    .MuiDrawer-paperAnchorBottom {
        border-radius: 12px 12px 0 0;
    }
    // .MuiDrawer-paperAnchorRight {
    //     border-radius: 12px 0 0 12px;
    // }
}

.MyDrawer__Drawer {
    display: flex;
    flex-direction: column;

    @media #{$small-down} {
        height: auto;
    }
}

.MyDrawer__Drawer__Header {
    padding: 16px 24px 0 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .PageHeader {
        margin-bottom: 0;
        flex: 1;
        align-items: center;
    }
}

.MyDrawer__Drawer__Loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
}

// .MyDrawer__Drawer__Header__Title {
//     // text-align: center;
//     font-weight: $font-weight-bold;
//     font-size: 18px;
// }

// .MyDrawer__Drawer__Header__CloseButton {
//     background-color: white;
//     border: 0;
//     color: $color-grey-lighter;
//     width: auto;
//     min-width: 0;
//     height: auto;
//     padding: 8px;
//     margin-right: -12px;

//     .icon {
//         width: 20px;
//         height: 20px;
//         color: black;
//     }
// }

.MyDrawer__Drawer__Body {
    flex: 1;
    overflow: auto;
    padding: 24px;
}
