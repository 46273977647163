@import '../../variables.scss';

.MyMenuButton__Menu {
    box-shadow: 0px 10px 16px -14px black;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.MyMenuButton__Menu__Title {
    padding: 4px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 12px;
    background-color: $color-grey-lightest;
    color: $color-grey-base;
}

.MyMenuButton__MenuList {
    padding: 0;
}

.MyMenuButton__MenuItem {
    list-style-type: none;
    white-space: nowrap;

    &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.MyMenuButton__MenuItemSwitch {
    text-decoration: none;
    height: 40px;
    min-width: 200px;
    padding: 0 24px;
    margin: 0;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: $font-weight-normal;
    gap: 12px;

    .MuiFormControlLabel-label {
        font-size: 13px !important;
        color: $color-grey-base !important;
    }
}

.MyMenuButton__MenuItemButton {
    text-decoration: none;
    display: flex;
    width: 100%;
    border-radius: 0;

    .MyButton__Inner {
        justify-content: flex-start;
        text-align: left;
    }
    .MyButton__Inner__Label {
        font-weight: normal;
    }

    &.Mui-disabled {
        background-color: white;
    }
}

.MyMenuButton__DrawerContainer {
    z-index: 2000 !important; // above any modals

    .MuiDrawer-paperAnchorBottom {
        border-radius: 12px 12px 0 0;
    }
    .MuiDrawer-paperAnchorRight {
        border-radius: 12px 0 0 12px;
    }
}

.MyMenuButton__Drawer {
    display: flex;
    flex-direction: column;

    @media #{$small-down} {
        height: auto;
    }
}

.MyMenuButton__Drawer__Header {
    position: relative;
    margin: 0 24px;
    padding: 24px 0;
    border-bottom: 1px solid $color-grey-lighter;
}

.MyMenuButton__Drawer__Header__Title {
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: 16px;
}

.MyMenuButton__Drawer__Header__CloseButton {
    position: absolute;
    background-color: white;
    border: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-grey-lightest;
    top: 22px;
    right: 0;

    .icon {
        color: black;
    }
}

.MyMenuButton__Drawer__Body {
    flex: 1;
    overflow: auto;
    padding: 24px;
}
