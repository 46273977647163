@import '../../variables.scss';

.body--CallbackPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    @media #{$small-down} {
        background-color: var(--override-login-background-color, white);
    }
    @media #{$medium-up} {
        background-color: var(--override-login-background-color, $color-primary-base);
    }
}
