@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;600;700;900&display=swap');
$fontFamily: Inter, sans-serif;

$font-weight-thin: 100;
$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

// Colors - Greys
$color-grey-darker: #2f2f2f;
$color-grey-dark: #454545;
$color-grey-base: #7e8486;
$color-grey-light: #b8b8b8;
$color-grey-lighter: #ece9e9;
$color-grey-lightest: #f5f5f5;

// Colors - Primary
$color-primary-darker: #0c355b;
$color-primary-dark: #1c66aa;
$color-primary-base: #009eda;
$color-primary-light: #7ecfee;
$color-primary-lighter: #c0edff;
$color-primary-lightest: #f0faff;

// Colors - Secondary
$color-secondary-darker: #141b26;
$color-secondary-dark: #2b323f;
$color-secondary-base: #6a758b;
$color-secondary-light: #dfe6f0;
$color-secondary-lighter: #f0f4fb;
$color-secondary-lightest: #f8faff;

// Colors - Accent
$color-accent-darker: #404a00;
$color-accent-dark: #7d8f0a;
$color-accent-base: #bfd730;
$color-accent-light: #e2f479;
$color-accent-lighter: #eef6bc;
$color-accent-lightest: #fcffe9;

// Colors - Danger (Reds)
$color-danger-darker: #650c0b;
$color-danger-dark: #811621;
$color-danger-base: #d83747;
$color-danger-light: #ff6272;
$color-danger-lighter: #ffbdc4;
$color-danger-lighter: #ffeded;

// Colors - Danger (Reds)
$color-warning-darker: #5f4400;
$color-warning-dark: #c2951d;
$color-warning-base: #f9d577;
$color-warning-light: #ffeab5;
$color-warning-lighter: #fffaed;
$color-warning-lighter: #fffdf6;

// Colors - Data
// $color-data-p-base: #9B57FB;
// $color-data-p-75: #B979FD;
// $color-data-p-50: #DCBCFE;
// $color-data-b-dark: #2CA0EC;
// $color-data-b-75: #5DB5EE;
// $color-data-b-50: #AEDAF7;
// $color-data-g-base: #4BC0C0;
// $color-data-g-75: #6FCDCD;
// $color-data-g-50: #B7E6E6;
// $color-data-y-dark: #FDB816;
// $color-data-y-base: #FFCD57;
// $color-data-y-75: #FFD779;
// $color-data-y-50: #FFEBBC;
// $color-data-y-30: #FCF1D1;
// $color-data-o-dark: #F48619;
// $color-data-o-base: #FF9F41;
// $color-data-o-75: #FFB267;
// $color-data-o-50: #FFD9B3;
// $color-data-r-base: #FF6384;
// $color-data-r-75: #FF6384;
// $color-data-r-50: #FFC1CE;
// $color-data-r-25: #F9CAD4;

// Colors - Misc
$color-link-base: $color-primary-base;
$color-link-dark: #005d82;
$color-warning: #fcc351;
$color-error: #d83747;
$color-checked-green: #00c797;

// breakpoints for responsive layouts
// each value is the minimum for the size
// small starts from 0px
$breakpoint-small: 448px; // small phones
$breakpoint-medium: 768px; // large phones / small tablet
$breakpoint-large: 1280px; // ipad -> small laptops

$screen: 'only screen';

$very-small-only: '#{$screen} and (max-width: #{$breakpoint-small - 1})';
$small-only: '#{$screen} and (min-width: #{$breakpoint-small}) and (max-width: #{$breakpoint-medium - 1})';
$medium-only: '#{$screen} and (min-width: #{$breakpoint-medium}) and (max-width: #{$breakpoint-large - 1})';
$large-only: '#{$screen} and (min-width: #{$breakpoint-large})';

$small-down: '#{$screen} and (max-width: #{$breakpoint-medium - 1})';
$small-up: '#{$screen} and (min-width: #{$breakpoint-small})';
$medium-down: '#{$screen} and (max-width: #{$breakpoint-large - 1})';
$medium-up: '#{$screen} and (min-width: #{$breakpoint-medium})';

// Site layout variables
$topBarHeight: 52px;
$sideBarWidthMedium: 240px;
$sideBarWidthLarge: 272px;

$modal-full-height: calc(100vh - 48px);

@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}
