@import '../variables.scss';

.DialogManager__dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
    overflow: auto;
    padding: 32px 48px;
    max-height: 100vh;

    background: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    outline: 0;

    animation: modal-slide-in 0.15s;

    @media #{$small-down} {
        padding: 24px;
        min-width: 240px;
        width: calc(100% - 16px);
        max-width: 400px;
    }
    @media #{$medium-up} {
        max-width: 592px;
    }
}

.DialogManager__dialog--loading {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    min-width: 100px;
    border-radius: 100%;
    height: 100px;
    padding: 0;
    animation: none;
    z-index: 1;
}

.DialogManager__dialog__main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.DialogManager__dialog__header {
    flex-direction: row;
    border-bottom: 1px solid $color-grey-lighter;
    width: 100%;
    flex: 1;
    font-weight: $font-weight-bold;
    font-size: 24px;
    border: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: calc(100% - 48px);
}

.DialogManager__dialog__title {
    font-weight: $font-weight-bold;
    font-size: 20px;
    margin-bottom: 12px;
}

.DialogManager__dialog__message {
    font-size: 16px;
    font-weight: $font-weight-normal;
    margin-bottom: 24px;
}

.DialogManager__dialog__buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    gap: 6px;

    @media #{$small-down} {
        flex-direction: column;
    }

    .MyButton {
        @media #{$small-down} {
            display: flex;
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }
        @media #{$medium-up} {
            float: right;
            min-width: 100px;
            margin-bottom: 5px;
            margin-left: 5px;
        }
    }
}

// slide from bottom
@keyframes modal-slide-in {
    from {
        top: 200%;
    }
}

.DialogManager__Toast {
    position: fixed;
    top: 24px;
    left: 24px;
    right: 24px;
    display: flex;
    justify-content: center;
    animation: toast-slide-in 0.5s;
    transform: translateY(0);
    z-index: 9999;

    &.hiding {
        animation: toast-slide-out 0.5s;
        transform: translateY(-200%);
    }
}

.DialogManager__Toast__Frame {
    background: white;
    border-radius: 8px;
    padding: 12px 24px;
    display: flex;
    border: 1px solid #e4e2e7;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
    align-items: center;

    .icon {
        margin-right: 24px;
        font-size: 24px;
    }
}

.DialogManager__Toast__Content {
    display: flex;
    flex-direction: column;

    .title {
        font-weight: $font-weight-semi-bold;
    }
    .message {
        flex: 1;
    }
}

@keyframes toast-slide-in {
    from {
        transform: translateY(-200%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes toast-slide-out {
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY(-200%);
    }
}
